import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/default';

import IndustryCard from 'components/content-blocks/customer-feature-benefit';
import Highlight from 'components/content-blocks/highlight';
import MediaObject from 'components/content-blocks/media-object';
import ProductVideo from 'components/content-blocks/product-video';
import { SignUpTrialLength } from 'components/forms/constants';
import Yoast from 'components/globals/yoast';
import { HeroHome } from 'components/hero/home';

import './styles/ifa.scss';

export default function Ifa() {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "ifa" } }) {
        edges {
          node {
            wordpress_id
        yoast_head_json {
          title
          description
          canonical
          og_title
          og_description
          og_image {
            url {
              source_url
            }
          }
          og_url
          og_type
          twitter_title
          twitter_description
          twitter_image {
            source_url
          }
        }
            acf {
              cards_1 {
                card {
                  title
                  image {
                    alt_text
                    source_url
                  }
                }
                header {
                  title
                }
              }
              media_object {
                body
                desktop_image {
                  alt_text
                  source_url
                }
                mobile_image {
                  alt_text
                  source_url
                }
                sub_title
                title
                button_text
                schedule_demo
                button_url
              }
              customer_feature_benefit {
                customer {
                  name
                  business
                  photo_desktop {
                    alt_text
                    source_url
                  }
                  photo_mobile {
                    alt_text
                    source_url
                  }
                  since
                }
                sub_title
                title
                copy
                stats {
                  descriptor_1
                  descriptor_2
                  descriptor_3
                  value_1
                  value_2
                  value_3
                }
              }
              video_block_1 {
                video_id
                image_desktop {
                  source_url
                }
                image_mobile {
                  source_url
                }
              }
              hero {
                title
                signup {
                  launch_modal_button_text
                  modal_submit_button_text
                  legal_text
                  alert_text
                  signup_cta_title
                }
                background_desktop_image {
                  alt_text
                  source_url
                }
                background_mobile_image {
                  alt_text
                  source_url
                }
              }
            }
          }
        }
      }
    }
  `);

  const pageContext = data.allWordpressPage.edges[0].node.acf;
  const yoast = data.allWordpressPage.edges[0].node.yoast_head_json;
  const hero = pageContext.hero;
  const mediaObject = pageContext.media_object;
  const cards = pageContext.cards_1;
  const customerCard = pageContext.customer_feature_benefit;
  const productVideo = pageContext.video_block_1;
  const trialLength = SignUpTrialLength.TRIAL_LENGTH_45_DAY;

  return (
    <Layout trialLength={ trialLength }>
      <Yoast { ...yoast } />
      <div className="ifa-page-container" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <HeroHome
          image_height={ 422 }
          image_width={ 560 }
          num_logos={ 8 }
          source_url="https://marketing-assets.wheniwork-production.com/2022/04/12140140/sprite_restaurant_customers.png"
          trialLength={ trialLength }
          { ...hero }
        />
        <MediaObject { ...mediaObject[0] } className="reverse schedule-demo" />
        <Highlight { ...cards } hasImage hasTitle />
        <MediaObject { ...mediaObject[1] } className="booth" />
        <IndustryCard { ...customerCard[0] } />
        <ProductVideo { ...productVideo } />
      </div>
    </Layout>
  );
}
